<template>
  <div id="app">
      <el-button id="drawer" icon="el-icon-s-fold" circle @click="drawer = true"></el-button>
      <el-drawer
        title="抽屉"
        :visible.sync="drawer"
        :with-header="false">
        <Icon msg="Icon"/>
      </el-drawer>
    <Home msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import Home from './components/Home.vue'
import Icon from './components/Icon.vue'
export default {
  name: 'App',
  components: {
    Home,
    Icon
  },
  data() {
  return {
    drawer: false,
  };
}
}
</script>

<style>
html,body{
  margin:0;
  padding:0;
}
#drawer{
  position:absolute;
  z-index: 999;
  top:10px;
  right:10px;
  font-size: 1em;
}
</style>
