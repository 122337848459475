// import Vue from 'vue'
// import App from './App.vue'

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import axios from 'axios'


Vue.use(ElementUI);

// 配置请求的根路径
axios.defaults.baseURL = 'http://d.com'
Vue.prototype.$http = axios
axios.interceptors.request.use(config => {
  // config.headers.Authorization = window.sessionStorage.getItem('token')
  // 在最后必须 return config
  return config
})
Vue.config.productionTip = false
new Vue({
  render: h => h(App)
}).$mount('#app')

// new Vue({
//   el: '#app',
//   render: h => h(App)
// });