<template>
  <el-carousel height="100vh" width="100vw" direction="vertical" :autoplay="false">
    <!-- <el-carousel-item v-for="item in 3" :key="item">
      <h3 class="medium">{{ item }}</h3>
    </el-carousel-item> -->
    
    <el-carousel-item>
      <!-- <Calendar msg="Calendar"/> -->
    </el-carousel-item>

    <el-carousel-item>
      <a href="https://beian.miit.gov.cn/">辽ICP备17003215号-1</a>
    </el-carousel-item>

  </el-carousel>
</template>

<script>
// import Calendar from './Element/Calendar4.vue'
// import Calendar from './Element/Ca.vue'
export default {
  name: 'HomeV',
  components: {
    // Calendar
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style scoped>
.el-carousel__item:nth-child(2n) {
  background: linear-gradient(0deg,#559ef1,#fff);
}
.el-carousel__item:nth-child(2n + 1) {
  background: linear-gradient(0deg,#fff,#559ef1);
}

a{
  width: 100vw;
  line-height: 40px;
  position:absolute;
  bottom: 0;;
  color: #fff;
  text-align:center;
  text-decoration:none;
}
</style>
